<template>
  <component
    :is="component"
    class="button"
    :class="{
      [color]: color,
      inverted,
      block,
      disabled,
      outlined,
      ['block-on-mobile']: blockOnMobile,
      loading: props.loading
    }"
    v-bind="buttonProps"
  >
    <div
      v-if="props.loading"
      class="loading-spinner"
    />

    <template v-else>
      <span class="button__text">
        <slot />
      </span>
      <span class="dot" />
      <SvgArrowRight class="arrow" />
    </template>
  </component>
</template>

<script setup lang="ts">
import { computed, defineProps, resolveComponent } from 'vue'
import type { Color } from '~/types'

const props = withDefaults(defineProps<{
  tag?: 'button' | 'link' | 'submit' | string;
  color?: Color;
  inverted?: boolean;
  block?: boolean;
  blockOnMobile?: boolean;
  disabled?: boolean;
  outlined?: boolean;
  loading?: boolean; // Ajouter cette propriété
}>(), {
  tag: 'button',
  color: 'pink',
  inverted: false,
  block: false,
  blockOnMobile: true,
  disabled: false,
  outlined: false,
  loading: false // Valeur par défaut
})

const component = computed(() => {
  switch (props.tag) {
    case 'link':
      return resolveComponent('NuxtLink')
    default:
      return 'button'
  }
})

const buttonProps = computed(() => {
  return {
    ...(['button', 'submit'].includes(props.tag) && { type: props.tag }),
    disabled: props.disabled || props.loading
  }
})
</script>

<style lang="scss" scoped>
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 10px 20px;
  background-color: $pink;
  color: $white;
  text-decoration: none;
  border: none;
  border-radius: $button-border-radius;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  transition: all 0.36s ease;
  min-width: 140px;
  min-height: 40px;

  .loading-spinner {
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    border: 3px solid #0000001A;
    border-radius: 50%;
    border-top: 3px solid $white;
    animation: spin 1s linear infinite;
    z-index: 1;
  }

  .dot {
    pointer-events: none;
    display: block;
    background: $white;
    margin-left: 1rem;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    transition: 0.36s ease;
  }

  .arrow {
    position: absolute;
    right: 0.9rem;
    top: 50%;
    transform: translateY(-50%);
    stroke: $pink;
    opacity: 0;
    transition: opacity 0.36s;
  }

  &.outlined {
    background-color: transparent;
    border: 1px solid $pink;
    color: $pink;

    .dot {
      background: $pink;
    }

    .arrow {
      stroke: $pink;
    }

    &:not(.disabled):hover,
    &:not(.disabled):focus {
      color: $white;
      background: $pink;
    }
  }

  &.block {
    width: 100%;
  }

  &.block-on-mobile {
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &__text {
    position: relative;
    z-index: 1;
    font-size: 1rem;
    pointer-events: none;
  }

  &.inverted {
    background-color: $white;
    color: $pink;
    border: 1px solid $pink;

    .dot {
      background: $pink;
    }

    .arrow {
      stroke: $white;
    }

    &:not(.disabled):hover,
    &:not(.disabled):focus {
      color: $white;
      background: $pink;
    }
  }

  &:not(.disabled):hover,
  &:not(.disabled):focus {
    color: $pink;
    background: $white;
    cursor: pointer;

    @each $name, $color in $homeloop-colors {
      &.#{$name} {
        color: $color;
        background: $white;

        &.outlined {
          color: $white;

          .arrow {
            stroke: $white;
          }

          @if $name == 'white' {
            color: $purple;

            .arrow {
              stroke: $purple;
            }
          }
        }

        &.inverted {
          color: $white;
          background: $white;

          .dot {
            background: $color;
          }
        }
      }
    }

    .dot {
      transform: scale(200);
      transform-origin: center;
    }

    .arrow {
      opacity: 1;
    }
  }

  @each $name, $color in $homeloop-colors {
    &.#{$name} {
      background-color: $color;
      color: $white;
      border: 1px solid $color;

      .arrow {
        stroke: $color;
      }

      &.outlined {
        background-color: transparent;
        border: 1px solid $color;
        color: $color;

        .dot {
          background: $color;
        }
      }

      &.inverted {
        background-color: $white;
        color: $color;

        .dot {
          background: $color;
        }

        .arrow {
          stroke: $white;
        }
      }
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
